<router-outlet></router-outlet>

<!--<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Factura Template</title>
</head>
<body style=" background-color: #f5f5f5; margin: 0px; padding: 0px; top:36px">
  <div style="background-color: #fff; border-radius: 12px 12px 0px 0px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) ">-->
    <!--Header Section-->
    <!--<div style="padding: 10px; background-color: #F5F5F5; margin-bottom: 20px; border-radius: 12px 12px 0px 0px; ">
      <table style="border-spacing: 0; width: 100%;  border-collapse: collapse;">
        <tr style="padding: 30px">
          <td style="vertical-align: middle; text-align: left;">
            <img style="height: 60px;" src="https://demorasweb.vesta-accelerate.com/assets/Recurso12LogoVestaHorizontal.png" />
          </td>
          <td>
            <div>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">600 metros del estadio nacional</span>
            </div>
            <div>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">Tegucigalpa, Honduras</span>
            </div>
            <div>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">504 - 2269-1600</span>
            </div>
          </td>
          <td>
            <div><span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">Centro comercial Megaplaza</span></div>
            <div><span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">7mo. piso, San Pedro Sula, Honduras</span></div>
            <div><span style="font-family: Calibri; font-size: 16px; font-weight: 300; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A">+504-33794430</span></div>
          </td>
          <td style="vertical-align: middle; text-align: right;">
            <a href="https://controlfinanciero.vesta-accelerate.com/" style="text-decoration: none; box-shadow: 0px 4px 12px 0px #00000040; background-color: #FFFFFF; border-radius: 8px; font-family: Calibri; font-size: 16px; font-weight: 700; line-height: 19.2px; text-align: left; padding: 12px; text-align: center; display: inline-block; width: auto; ">
              <span style="color: #E93636;">Ir al Sistema</span>
            </a>
          </td>
        </tr>
      </table>
    </div>-->

    <!--Title Section-->
    <!--<div><span style="font-family: Calibri; font-size: 40px; font-weight: 400; line-height: 48px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B; padding-left: 15px;">Notificación de Registro</span></div>-->

    <!--Client and Invoice Info-->
    <!--<div style=" padding-left: 13px;">

      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <td style="padding: 10px; vertical-align: top;">
            <div><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3;">Cliente</span></div>
            <div><span style="color: #3B3B3B; font-family: Calibri; font-size: 18px; font-weight: 400; line-height: 21.6px; letter-spacing: 0.02em; text-align: left;">Livsmart</span></div>
          </td>

          <td style="padding: 10px; vertical-align: top;">
              <div><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3;">Fecha de Envío</span></div>
              <div><span style="color: #3B3B3B; font-family: Calibri; font-size: 18px; font-weight: 400; line-height: 21.6px; letter-spacing: 0.02em; text-align: left;">Agosto 31, 2024</span></div>
          </td>

          <td style="padding: 10px; vertical-align: top; text-align: right;">
          </td>
        </tr>
        <tr>
          <td style="padding: 10px; vertical-align: top;">
            <div><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3;">Usuario</span></div>
            <div><span style="color: #3B3B3B; font-family: Calibri; font-size: 18px; font-weight: 400; line-height: 21.6px; letter-spacing: 0.02em; text-align: left;">Juan Carlos Espinal</span></div>
          </td>
          <td style="padding: 10px; vertical-align: top;">
            <div>
              <table style="border-collapse: collapse;">
                <tr>
                  <td>
                    <div>
                      <span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3;">Fecha Inicio</span>
                    </div>
                    <div>
                      <span style="color: #3B3B3B; font-family: Calibri; font-size: 18px; font-weight: 400; line-height: 21.6px; letter-spacing: 0.02em; text-align: left;">26/08/2024</span>
                    </div>
                  </td>
                  <td style="padding: 0px 0px 0px 40px">
                    <div>
                      <span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3;">Fecha Fin</span>
                    </div>
                    <div>
                      <span style="color: #3B3B3B; font-family: Calibri; font-size: 18px; font-weight: 400; line-height: 21.6px; letter-spacing: 0.02em; text-align: left;">31/08/2024</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>

        </tr>
      </table>
    </div>
    <br />
    <br />
    <table style="border-collapse: collapse; width: 100%">
      <tr>
        <td>
          <div style="padding-left: 15px;"><span style="font-family: Calibri; font-size: 24px; font-weight: 600; line-height: 28.8px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B; ">Detalle facturas</span></div>
        </td>
        <td style="float:inline-end">
          <div style="padding: 0px 10px 0px 0px">
            <div style="text-align: center"><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3; ">Monto Total</span></div>
            <div><span style="font-family: Calibri; font-size: 40px; font-weight: 700; line-height: 48px; letter-spacing: 0.02em; text-align: right; color: #1B3280;">$82,477</span></div>
          </div>
        </td>
      </tr>
    </table>-->
    <!--Invoice Details Table-->
    <!--<div style="padding-right: 15px; padding-left: 15px; background-color: #F5F5F5; border-radius: 8px;">
      <table style=" width: 100%; margin-bottom: 20px;  border-collapse: collapse;">
        <thead style="border: none;">
          <tr>
            <th><span style="font-family: Calibri; font-size: 18px; font-weight: 700; line-height: 21.6px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Descripción</span></th>
            <th><span style="font-family: Calibri; font-size: 18px; font-weight: 700; line-height: 21.6px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Tipo de Factura</span></th>
            <th><span style="font-family: Calibri; font-size: 18px; font-weight: 700; line-height: 21.6px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Hora</span></th>
            <th><span style="font-family: Calibri; font-size: 18px; font-weight: 700; line-height: 21.6px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Proveedor</span></th>
            <th><span style="font-family: Calibri; font-size: 18px; font-weight: 700; line-height: 21.6px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Monto</span></th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B;">Green Beans Per Bag</span><br />
              <span style="font-family: Calibri; font-size: 14px; font-weight: 400; line-height: 16.8px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A; ">Green beans + Grain Bag + Goni Bag</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">333</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; font-size: 16px; font-weight: 700; line-height: 19.2px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">$81585</span>
            </td>
          </tr>

          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B;">Forwarding</span><br />
              <span style="font-family: Calibri; font-size: 14px; font-weight: 400; line-height: 16.8px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A; ">Factory to Soekarno hatta Port + Soekarno Hatta Port to Brisbane Port</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">4</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; font-size: 16px; font-weight: 700; line-height: 19.2px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">$680</span>
            </td>
          </tr>

          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B;">Documents</span><br />
              <span style="font-family: Calibri; font-size: 14px; font-weight: 400; line-height: 16.8px; letter-spacing: 0.02em; text-align: left; color: #7A7A7A; ">SKA (Local Certificate of Origin) + ETK (Local Export Legalization)</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">8</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; font-size: 16px; font-weight: 700; line-height: 19.2px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right;">$212</span>
            </td>
          </tr>
        </tbody>
        <tfoot style=" border: none;">
          <tr>
            <th colspan="4" style="text-align: end; padding: 10px">
              <span style="font-family: Calibri; font-size: 16px; font-weight: 700; line-height: 19.2px; letter-spacing: 0.02em; text-align: center; color: #1B3280;">Total Amount</span>
            </th>
            <th>
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; font-weight: 500; line-height: 16.8px; letter-spacing: 0.02em; text-align: right; ">$82477</span>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>

    <br />-->
    <!--Terms and Conditions-->
    <!--<div style="padding-left: 15px;">
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 24px; font-weight: 700; line-height: 28.8px; letter-spacing: 0.06em; text-align: left; ">Términos & Condiciones</span></div>
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px; font-weight: 400; line-height: 19.2px; text-align: left; ">Lorem Ipsum</span></div>
    </div>

    <br />-->
    <!--Partner Information-->

    <!--<div style="padding-left: 15px;">
      <div><span style="font-family: Calibri; font-size: 24px; font-weight: 700; line-height: 28.8px; letter-spacing: 0.06em; text-align: left; color: #3B3B3B;">Partner</span></div>
      <div><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; text-align: left; color: #3B3B3B;">Isabella Gutierrez</span></div>
      <div><span style="font-family: Calibri; font-size: 16px; font-weight: 400; line-height: 19.2px; text-align: left; color: #3B3B3B;">igutierrez@grupovesta.net</span></div>
    </div>

    <br />-->
    <!--Footer-->
    <!--<table style="width: 100%; border-collapse: collapse;">
      <tr>
        <td style="vertical-align: top; text-align: center;">
          <div>
            <span style="font-family: Calibri; font-size: 40px; font-weight: 400; line-height: 48px; letter-spacing: 0.02em; text-align: left; color: #1B3280;">www.grupovesta.com</span>
          </div>
        </td>
      </tr>
      <tr>
        <td style=" vertical-align: top; text-align: center ">
          <div>
            <span style="font-family: Calibri; font-size: 24px; font-weight: 400; line-height: 28.8px; letter-spacing: 0.02em; text-align: left; color: #3B3B3B;">Honduras - El Salvador - Guatemala - Nicaragua</span>
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding: 10px; vertical-align: top;">
          <div>
            <span style="font-family: Calibri; font-size: 14px; font-weight: 300; line-height: 16.8px; letter-spacing: 0.02em; text-align: left; color: #A3A3A3; ">A1.03.09.24</span>
          </div>
        </td>
      </tr>
    </table>
    <br />
  </div>
</body>
</html>-->
